<template>
  <div>
    <div :class="isMobile ? 'm-2' : 'w-50 m-30 mx-auto'" v-if="userInfo && userInfo.userName && userInfo.photoUrl">
      <v-card class="mx-auto box-white">
        <v-img class="mx-auto my-5 center circle-image rounded-circle" :src="userInfo.photoUrl" />
        <p class="m-3 center font-bold font-18">{{ userInfo.userName }}</p>
        <p>&nbsp;</p>
      </v-card>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow" v-if="userInfo === null"></b-spinner>
      <p class="mt-5" v-if="userInfo === null">{{ $t('alert_please_wait') }}</p>
      <p v-else>{{ $t('no_data_found') }}</p>
    </div>
  </div>
</template>

<style scoped>
  .circle-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
</style>

<script>
import { getDateStringFromTimestamp, getCountryCityFromAddress } from '../../../functions';
import { firebaseAuth, firestore } from '../../../main';
import { collection, query, where, onSnapshot, limit } from 'firebase/firestore';

export default {
  name: 'UserDetail',
/*metaInfo() {
    const title = 'eventbox | ' + this.$route.params.userName;
    const description = this.$route.params.userName;
    const imageUrl = this.userInfo ? this.userInfo.photoUrl : '';
    const siteUrl = `https://eventboxde.com/u/${this.$route.params.userName}`;
    return {
      title: title,
      meta: [
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: siteUrl },
        { property: 'og:site_name', content: 'eventbox' },
        { property: 'og:image', itemprop: 'image primaryImageOfPage', content: imageUrl },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:domain', content: 'eventboxde.com' },
        { name: 'twitter:title', property:'og:title', itemprop: 'name', content: title },
        { name: 'twitter:description', property:'og:description', itemprop:'description', content: description },
        { name: 'twitter:image', content: imageUrl }
      ]
    }
  },*/
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      userInfo: null,
      snapUserInfo: null
    };
  },
  mounted() {
    if (firebaseAuth.currentUser) {
      this.getUserInfoTask();
    } else {
      firebaseAuth.onAuthStateChanged(user => {
        if (user) {
          this.getUserInfoTask();
        }
      });
    }
  },
  destroyed() {
    if (this.snapUserInfo) {
      this.snapUserInfo();
    }
  },
  methods: {
    getDateStringFromTimestamp,
    getCountryCityFromAddress,
    getUserInfoTask() {
      if (this.snapUserInfo) {
        return;
      }
      const q = query(collection(firestore, 'user'), where('userName', '==', this.$route.params.userName), limit(1));
      this.snapUserInfo = onSnapshot(q, querySnapshot => {
        var userInfo = null;
        querySnapshot.forEach(doc => userInfo = doc.data());
        if (userInfo) {
          if (userInfo.deletedAt) {
            this.$toast.error(this.$t('alert_this_account_has_been_deleted'));
            this.$router.push(`/`);
          } else {
            this.userInfo = userInfo;
          }
        } else {
          this.userInfo = {
            userName: '',
            photoUrl: ''
          }
        }
      })
    }
  }
};
</script>